import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, CSSReset, Box, Container } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import { Contacts } from './components/Contact/index';
import { Contact } from './components/Contact/item';

import { Loans } from './components/Loan/index';
import { Loan } from './components/Loan/item';

import { Cards } from './components/Card/index';
import { Card } from './components/Card/item';
import { Login } from './components/Login';

import appTheme from './theme';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import './style/main.css';
import { AuthProvider } from './providers/auth';
import { useAuth } from './hooks/auth';
import { Departments } from './components/Department';
import { Branches } from './components/Branch';
import { Users } from './components/Users';
import { EditUser } from './components/Users/edit';
import { EditBranch } from './components/Branch/edit';
import { EditDepartment } from './components/Department/edit';
import { ChangePassword } from './components/Users/change-password';
import { Loader } from './components/Loader';
import { ShortComments } from './components/ShortComment';
import { EditShortComment } from './components/ShortComment/edit';

const queryCache = new QueryCache();

function Home() {
  const [token, user] = useAuth();
  if (!user) {
    return <Loader />;
  }

  if (user && user.role === 'admin') {
    return <Redirect to="/users" />;
  }

  if (
    user &&
    user.role === 'user' &&
    user.accessLevel === 'DEPARTMENT_MANAGER'
  ) {
    // Marketing
    if (user.departmentId === 1) {
      return <Redirect to="/contacts" />;
    }
    // Karta
    if (user.departmentId === 2) {
      return <Redirect to="/cards" />;
    }
    // Kredi
    if (user.departmentId === 3) {
      return <Redirect to="/loans" />;
    }
  }

  return <Redirect to="/contacts" />;
}

const PrivateRoute = ({ children, ...rest }) => {
  const [token] = useAuth();

  return (
    <Route {...rest}>
      {token ? <Box>{children}</Box> : <Redirect to="/login" />}
    </Route>
  );
};

function Routes() {
  return (
    <>
      <Route exact path="/login">
        <Login />
      </Route>
      <Container maxW="container.xl">
        <Switch>
          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>
          <PrivateRoute path="/branches" exact>
            <Branches />
          </PrivateRoute>
          <PrivateRoute path="/short-comment" exact>
            <ShortComments />
          </PrivateRoute>
          <PrivateRoute path="/short-comment/:id" exact>
            <EditShortComment />
          </PrivateRoute>
          <PrivateRoute path="/users" exact>
            <Users />
          </PrivateRoute>
          <PrivateRoute path="/changepassword" exact>
            <ChangePassword />
          </PrivateRoute>
          <PrivateRoute path="/user/:id" exact>
            <EditUser />
          </PrivateRoute>
          <PrivateRoute path="/branch/:id" exact>
            <EditBranch />
          </PrivateRoute>
          <PrivateRoute path="/department/:id" exact>
            <EditDepartment />
          </PrivateRoute>
          <PrivateRoute path="/departments" exact>
            <Departments />
          </PrivateRoute>
          <PrivateRoute path="/contacts" exact>
            <Contacts />
          </PrivateRoute>
          <PrivateRoute path="/contact/:id" exact>
            <Contact />
          </PrivateRoute>
          <PrivateRoute path="/cards" exact>
            <Cards />
          </PrivateRoute>
          <PrivateRoute path="/card/:id" exact>
            <Card />
          </PrivateRoute>
          <PrivateRoute path="/loans" exact>
            <Loans />
          </PrivateRoute>
          <PrivateRoute path="/loan/:id" exact>
            <Loan />
          </PrivateRoute>
        </Switch>
      </Container>
    </>
  );
}

function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ThemeProvider theme={appTheme}>
        <AuthProvider>
          <CSSReset />
          <Router>
            <Navbar />
            <Routes />
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </ReactQueryCacheProvider>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
