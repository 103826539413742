import React, { useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { Loader } from '../Loader';
import { Header } from '../Header';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useBranch,
  useCreateBranch,
  useUpdateBranch,
} from '../../hooks/branch';
import { useParams } from 'react-router';
import { TextInput } from '../Form';

export const EditForm = ({ item, createMode, editMode, onSave }) => {
  const toast = useToast();
  const [updateBranch] = useUpdateBranch(item.id);
  const [createBranch] = useCreateBranch();

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required('I detyrueshëm'),
    address: Yup.string().required('I detyrueshëm'),
  });

  const formik = useFormik({
    initialValues: {
      name: item.name,
      address: item.address,
    },
    onSubmit: async ({ name, address }) => {
      formik.setSubmitting(true);
      if (createMode) {
        await createBranch({ name, address });
      }
      if (editMode) {
        await updateBranch({ name, address });
      }
      formik.setSubmitting(false);
      onSave && onSave()
      toast({
        title: `Ndryshimet u ruajten`,
        status: 'success',
        isClosable: true,
      });
    },
    validationSchema: SignupSchema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput formik={formik} id="name" name="Emri" />
      <TextInput formik={formik} id="address" name="Adresa" />

      <Button
        mt={4}
        colorScheme="blue"
        isLoading={formik.isSubmitting}
        type="submit"
      >
        Ruaj
      </Button>
    </form>
  );
};

export function EditBranch({ children, ...props }) {
  const { id } = useParams();
  const { status, data, error, isFetching } = useBranch(id);

  if (isFetching) {
    return <Loader />;
  }

  const { items, totalPages } = data || { items: [], totalPages: 0 };

  return (
    <>
      <Header title={`Dega ` + (isFetching ? '...' : data.name)}></Header>
      <EditForm item={data} editMode />
    </>
  );
}
