import React, { useState } from 'react';
import {
  Button,
  useToast,
} from '@chakra-ui/react';
import { Loader } from '../Loader';
import { Header } from '../Header';
import { useUpdateUser, useUser } from '../../hooks/auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useBranches } from '../../hooks/branch';
import { useDepartments } from '../../hooks/department';
import { useParams } from 'react-router';
import { SelectInput, TextInput } from '../Form';


const EditForm = ({ user }) => {
  const toast = useToast();
  const { data: branchData, isLoading: branchLoading } = useBranches(0);
  const { items: branches } = branchData || { items: [] };
  const [updateUser] = useUpdateUser(user.id);

  const { data: departmentData, isLoading: depertmentLoading } =
    useDepartments(0);
  const { items: departments } = departmentData || { items: [] };

  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      username: user.username,
      email: user.email,
      accessLevel: user.accessLevel,
      department: user.departmentId || 0,
      branch: user.branchId || 0,
    },
    onSubmit: ({ accessLevel, branch, department }) => {
      formik.setSubmitting(true);
      updateUser({ accessLevel, branchId: branch || 0, departmentId: department  || 0});
      formik.setSubmitting(false);
      toast({
        title: `Ndryshimet u ruajten`,
        status: 'success',
        isClosable: true,
      });
    },
    validationSchema: SignupSchema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput formik={formik} id="username" name="Emri" disabled />
      <TextInput formik={formik} id="email" name="E-mail" disabled />
      <SelectInput
        formik={formik}
        id="accessLevel"
        name="Roli"
        data={[
          {
            value: 'BANKER',
            label: 'Bankier',
          },
          {
            value: 'BRANCH_MANAGER',
            label: 'Drejtor dege',
          },
          {
            value: 'DEPUTY_BRANCH_MANAGER',
            label: 'Zv. drejtor dege',
          },
          {
            value: 'DEPARTMENT_MANAGER',
            label: 'Drejtor departamenti',
          },
        ]}
      />
      <SelectInput
        formik={formik}
        id="department"
        name="Departamenti"
        data={departments.map(item => ({ value: item.id, label: item.name }))}
      />
      <SelectInput
        formik={formik}
        id="branch"
        name="Dega"
        data={branches.map(item => ({ value: item.id, label: item.name }))}
      />

      <Button
        mt={4}
        colorScheme="blue"
        isLoading={formik.isSubmitting}
        type="submit"
      >
        Ruaj
      </Button>
    </form>
  );
};

export function EditUser({ children, ...props }) {
  const { id } = useParams();
  const { status, data, error, isFetching } = useUser(id);

  if (isFetching) {
    return <Loader />;
  }

  const { items, totalPages } = data || { items: [], totalPages: 0 };

  return (
    <>
      <Header
        title={`Përdoruesi ` + (isFetching ? '...' : data.username)}
      ></Header>
      <EditForm user={data} />
    </>
  );
}
