import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react';

export function TextInput({ formik, name, id, disabled = false, ...props }) {
  return (
    <FormControl isInvalid={formik.errors[id] && formik.touched[id]}>
      <FormLabel htmlFor={id}>{name}</FormLabel>
      <Input
        onChange={formik.handleChange}
        value={formik.values[id]}
        id={id}
        disabled={disabled}
        {...props}
      />
      <FormErrorMessage>{formik.errors[id]}</FormErrorMessage>
    </FormControl>
  );
}

export function PasswordInput({
  formik,
  name,
  id,
  disabled = false,
  ...props
}) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormControl isInvalid={formik.errors[id] && formik.touched[id]}>
      <FormLabel htmlFor={id}>{name}</FormLabel>
      <InputGroup size="md">
        <Input
          pr="4.5rem"
          type={show ? 'text' : 'password'}
          onChange={formik.handleChange}
          value={formik.values[id]}
          id={id}
          disabled={disabled}
          {...props}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            size="sm"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              handleClick();
            }}
          >
            {show ? 'Fshih' : 'Shfaq'}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{formik.errors[id]}</FormErrorMessage>
    </FormControl>
  );
}

export function TextareaInput({
  formik,
  name,
  id,
  disabled = false,
  ...props
}) {
  return (
    <FormControl isInvalid={formik.errors[id] && formik.touched[id]}>
      <FormLabel htmlFor={id}>{name}</FormLabel>
      <Textarea
        style={{ border: '1px solid lightgray' }}
        onChange={formik.handleChange}
        value={formik.values[id]}
        id={id}
        disabled={disabled}
        {...props}
      />
      <FormErrorMessage>{formik.errors[id]}</FormErrorMessage>
    </FormControl>
  );
}

export function SelectInput({
  formik,
  name,
  id,
  disabled = false,
  data = [],
  ...props
}) {
  return (
    <FormControl isInvalid={formik.errors[id] && formik.touched[id]}>
      <FormLabel htmlFor={id}>{name}</FormLabel>
      <Select
        placeholder="Asnjë"
        disabled={disabled}
        id={id}
        onChange={formik.handleChange}
        value={formik.values[id]}
        {...props}
      >
        {data.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{formik.errors[id]}</FormErrorMessage>
    </FormControl>
  );
}
