import React, { useState } from 'react';
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Box,
  Tag,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader';
import { Header } from '../Header';
import {
  useCreateUser,
  useDeleteUser,
  usePasswordReset,
  useUsers,
} from '../../hooks/auth';
import { SelectInput, TextInput } from '../Form';
import { useDepartments } from '../../hooks/department';
import { useBranches } from '../../hooks/branch';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { formatDate } from '../../utils/date';

function getRole(role = '', level = '') {
  if (role === 'admin') {
    return (
      <Box>
        <Tag size={'md'} variant="solid" colorScheme="blue">
          Administrator
        </Tag>
      </Box>
    );
  }
  if (level === 'BANKER') {
    return (
      <Box>
        <Tag size={'md'} variant="solid" colorScheme="gray">
          Bankier
        </Tag>
      </Box>
    );
  }
  if (level === 'BRANCH_MANAGER') {
    return (
      <Box>
        <Tag size={'md'} variant="solid" colorScheme="orange">
          Drejtor dege
        </Tag>
      </Box>
    );
  }
  if (level === 'DEPUTY_BRANCH_MANAGER') {
    return (
      <Box>
        <Tag size={'md'} variant="solid" colorScheme="red">
          Zv. drejtor dege
        </Tag>
      </Box>
    );
  }
  if (level === 'DEPARTMENT_MANAGER') {
    return (
      <Box>
        <Tag size={'md'} variant="solid" colorScheme="green">
          Drejtor departamenti
        </Tag>
      </Box>
    );
  }
  return (
    <Box>
      <Tag size={'md'} variant="solid" colorScheme="gray">
        Bankier
      </Tag>
    </Box>
  );
}


const CreateForm = ({ item, onSave }) => {
  const { data: branchData, isLoading: branchLoading } = useBranches(0);
  const { items: branches } = branchData || { items: [] };
  const [createUser, resp] = useCreateUser();

  const { data: departmentData, isLoading: depertmentLoading } =
    useDepartments(0);
  const { items: departments } = departmentData || { items: [] };

  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      username: item.username,
      email: item.email,
      accessLevel: item.accessLevel,
      department: item.departmentId || 0,
      branch: item.branchId || 0,
    },
    onSubmit: async ({ username, email, accessLevel, branch, department }) => {
      try {
        formik.setSubmitting(true);
        if (accessLevel === 'ADMIN') {
          await createUser({
            username,
            email,
            role: 'admin',
          });
        } else {
          await createUser({
            username,
            email,
            role: 'user',
            accessLevel,
            branchId: branch,
            departmentId: department,
          });
        }
        formik.setSubmitting(false);
        onSave && onSave();
      } catch (err) {
        console.log(err);
      }
    },
    validationSchema: SignupSchema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput formik={formik} id="username" name="Emri" />
      <TextInput formik={formik} id="email" name="E-mail" />
      <SelectInput
        formik={formik}
        id="accessLevel"
        name="Roli"
        data={[
          {
            value: 'ADMIN',
            label: 'Administrator',
          },
          {
            value: 'BANKER',
            label: 'Bankier',
          },
          {
            value: 'BRANCH_MANAGER',
            label: 'Drejtor dege',
          },
          {
            value: 'DEPUTY_BRANCH_MANAGER',
            label: 'Zv. drejtor dege',
          },
          {
            value: 'DEPARTMENT_MANAGER',
            label: 'Drejtor departamenti',
          },
        ]}
      />
      <SelectInput
        formik={formik}
        id="department"
        name="Departamenti"
        data={departments.map(item => ({ value: item.id, label: item.name }))}
        required={formik.values.accessLevel === 'DEPARTMENT_MANAGER'}
        disabled={formik.values.accessLevel !== 'DEPARTMENT_MANAGER'}
      />
      <SelectInput
        formik={formik}
        id="branch"
        name="Dega"
        data={branches.map(item => ({ value: item.id, label: item.name }))}
        required={formik.values.accessLevel !== 'ADMIN'}
        disabled={formik.values.accessLevel === 'ADMIN'}
      />

      <Button
        mt={4}
        colorScheme="blue"
        isLoading={formik.isSubmitting}
        type="submit"
      >
        Ruaj
      </Button>
    </form>
  );
};

export function NormalUsers({ children, isFetching, items }) {
  const toast = useToast();
  const [deleteUser] = useDeleteUser();
  const [resetPassword] = usePasswordReset();

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Header subtitle title="Përdorues"></Header>
      <Table variant="striped" colorScheme="gray" size="sm">
        <Thead>
          <Tr>
            <Th style={{width: "200px"}}>Emri</Th>
            <Th style={{width: "200px"}}>E-mail</Th>
            <Th>Roli</Th>
            <Th>Departamenti</Th>
            <Th>Dega</Th>
            <Th>Rregjistrimi</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map(item => (
            <Tr>
              <Td>{item.username}</Td>
              <Td>{item.email}</Td>
              <Td>{getRole(item.role, item.accessLevel)}</Td>
              <Td>{item.department ? item.department.name : ''}</Td>
              <Td>{item.branch ? item.branch.name : ''}</Td>
              <Td>{formatDate(item.created_at)}</Td>
              <Td style={{ width: '100px' }}>
                <Menu>
                  <MenuButton
                    as={Button}
                    colorScheme="blue"
                    variant="ghost"
                    size="sm"
                  >
                    Veprime
                  </MenuButton>
                  <MenuList>
                    <MenuItem>
                      <Link to={`user/` + item.id}>Redakto</Link>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (
                          window.confirm(
                            `Jeni të sigurtë që doni të rivendosni automatikisht fjalëkalimin për përdoruesin ${item.username}?`
                          )
                        ) {
                          resetPassword({ email: item.email });
                          toast({
                            title: `Fjalëkalimi i përdoruesit ${item.username} u ndryshua.`,
                            status: 'success',
                            isClosable: true,
                          });
                        }
                      }}
                    >
                      Rivendos fjalëkalimin
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (
                          window.confirm(
                            `Jeni të sigurtë që doni të fshini përdoruesin ${item.username}?`
                          )
                        ) {
                          deleteUser(item.id);
                          toast({
                            title: `Përdoruesi ${item.username} u fshi.`,
                            status: 'success',
                            isClosable: true,
                          });
                        }
                      }}
                    >
                      Fshi
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export function AdminUsers({ children, isFetching, items }) {
  const toast = useToast();
  const [deleteUser] = useDeleteUser();
  const [resetPassword] = usePasswordReset();

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Header subtitle title="Administratorë"></Header>
      <Table variant="striped" colorScheme="gray" size="sm">
        <Thead>
          <Tr>
            <Th style={{width: "200px"}}>Emri</Th>
            <Th style={{width: "200px"}}>E-mail</Th>
            <Th>Roli</Th>
            <Th>Departamenti</Th>
            <Th>Dega</Th>
            <Th>Rregjistrimi</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map(item => (
            <Tr>
              <Td>{item.username}</Td>
              <Td>{item.email}</Td>
              <Td>{getRole(item.role, item.accessLevel)}</Td>
              <Td>{item.department ? item.department.name : ''}</Td>
              <Td>{item.branch ? item.branch.name : ''}</Td>
              <Td>{formatDate(item.created_at)}</Td>
              <Td style={{ width: '100px' }}>
                <Menu>
                  <MenuButton
                    as={Button}
                    colorScheme="blue"
                    variant="ghost"
                    size="sm"
                  >
                    Veprime
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        if (
                          window.confirm(
                            `Jeni të sigurtë që doni të rivendosni automatikisht fjalëkalimin për përdoruesin ${item.username}?`
                          )
                        ) {
                          resetPassword({ email: item.email });
                          toast({
                            title: `Fjalëkalimi i përdoruesit ${item.username} u ndryshua.`,
                            status: 'success',
                            isClosable: true,
                          });
                        }
                      }}
                    >
                      Rivendos fjalëkalimin
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (
                          window.confirm(
                            `Jeni të sigurtë që doni të fshini përdoruesin ${item.username}?`
                          )
                        ) {
                          deleteUser(item.id);
                          toast({
                            title: `Përdoruesi ${item.username} u fshi.`,
                            status: 'success',
                            isClosable: true,
                          });
                        }
                      }}
                    >
                      Fshi
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export function Users({ children, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(0);
  const {
    data: adminData,
    isFetching: adminFetching,
    refetch: refetchAdmin,
  } = useUsers(page, { role: 'admin' });
  const {
    data: userData,
    isFetching: userFetching,
    refetch: refetchUsers,
  } = useUsers(page, { role: 'user' });

  const { items: admins } = adminData || { items: [] };
  const { items: users } = userData || { items: [] };

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Shto një përdorues</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CreateForm
              item={{ id: 0, username: '', email: '', accessLevel: '' }}
              onSave={() => {
                onClose();
                refetchAdmin();
                refetchUsers();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Header title="Lista e përdoruesve">
        <Button colorScheme="blue" variant="link" onClick={onOpen}>
          Shto përdorues
        </Button>
      </Header>

      <AdminUsers
        items={admins}
        isFetching={adminFetching}
        children={children}
        {...props}
      />
      <NormalUsers
        items={users}
        isFetching={userFetching}
        children={children}
        {...props}
      />
    </>
  );
}
