import React from 'react';
import {
  HStack,
  Flex,
  Heading,
  Text,
  Stack,
  Avatar,
  Spacer,
  Box,
} from '@chakra-ui/react';
import { formatDate } from '../../utils/date';

const CommentWrapper = ({ bg, children }) => {
  return (
    <Box marginBottom={5} bg={"#f3f3f3"} borderLeftWidth={10} borderLeftStyle="solid" borderColor={bg}>
      <HStack width="100%" spacing="10px">
        {children}
      </HStack>
    </Box>
  );
};

const CommentContent = ({ children }) => {
  return (
    <Stack p={4} width="100%" rounded={'md'} pos={'relative'}>
      {children}
    </Stack>
  );
};

const CommentHeading = ({ date, children }) => {
  return (
    <HStack spacing="24px">
      <Heading as={'h3'} fontSize={'sm'}>
        {children}
      </Heading>
      <Spacer />
      <Text fontSize="sm" color="gray">
        {date}
      </Text>
    </HStack>
  );
};

const CommentText = ({ children }) => {
  return <Text fontSize={'sm'}>{children}</Text>;
};

const CommentAvatar = ({ name }) => {
  return (
    <Flex align={'center'} direction={'column'}>
      <Avatar name={name} ml={4} mb={2} />
    </Flex>
  );
};

const statusColors = {
  TODO: 'gray.200',
  IN_PROCESS: 'blue.200',
  DONE: 'green.200',
  CLOSED: 'red.200',
};
export const EventUpdate = ({ status, name, message, date, deleted }) => {
  return (
    <CommentWrapper bg={statusColors[status] || "blue.200"}>
      <CommentAvatar name={name} />
      <CommentContent>
        <CommentHeading date={formatDate(date)}>{name} {deleted ? "(fshirë)" : null}</CommentHeading>
        <CommentText>{message}</CommentText>
      </CommentContent>
    </CommentWrapper>
  );
};
