import React, { useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { Loader } from '../Loader';
import { Header } from '../Header';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import { TextInput } from '../Form';
import { useDepartment, useUpdateDepartment } from '../../hooks/department';

const EditForm = ({ item }) => {
  const toast = useToast();
  const [update] = useUpdateDepartment(item.id);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required('I detyrueshëm'),
  });

  const formik = useFormik({
    initialValues: {
      name: item.name,
    },
    onSubmit: ({ name }) => {
      formik.setSubmitting(true);
      update({ name });
      formik.setSubmitting(false);
      toast({
        title: `Ndryshimet u ruajten`,
        status: 'success',
        isClosable: true,
      });
    },
    validationSchema: SignupSchema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput formik={formik} id="name" name="Emri" />

      <Button
        mt={4}
        colorScheme="blue"
        isLoading={formik.isSubmitting}
        type="submit"
      >
        Ruaj
      </Button>
    </form>
  );
};

export function EditDepartment({ children, ...props }) {
  const { id } = useParams();
  const { status, data, error, isFetching } = useDepartment(id);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Header title={`Departamenti ` + (isFetching ? '...' : data.name)}></Header>
      <EditForm item={data} />
    </>
  );
}
