import { useState } from 'react';
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
} from '@chakra-ui/react';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { useLogin, useAuth } from '../hooks/auth';
import { useHistory } from 'react-router-dom';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

export const Login = () => {
  const [[login]] = useLogin();
  const history = useHistory();
  const [token, user, setAuth] = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  function onLogin(event) {
    event.preventDefault();
    event.stopPropagation();
    login({ email, password })
      .then(({token, user}) => {
        setAuth(token, user);
        history.push('/');
      })
      .catch(err => {
        alert(`Problem me identifikimin. ${err.message}`);
      });
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="60vh"
      backgroundColor="white.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack flexDir="column" justifyContent="center" alignItems="center">
        {/* <Avatar bg="teal.500" /> */}
        <Heading color="gray.400">Identifikohu</Heading>
        <Box minW={{ base: '90%', md: '468px' }}>
          <form>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    isRequired
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Fjalëkalimi"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    isRequired
                  />
                  {/* <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? 'Fsheh' : 'Shfaq'}
                    </Button>
                  </InputRightElement> */}
                </InputGroup>
                {/* <FormHelperText textAlign="right">
                  <Link>Harruat fjalëkalimin?</Link>
                </FormHelperText> */}
              </FormControl>
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="blue"
                width="full"
                onClick={onLogin}
              >
                Identifikohu
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};
