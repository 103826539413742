import React, { useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FlexContainer } from '../Container';
import { useApplicationContacts } from '../../hooks/contact';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader';
import {
  useApplicationLoans,
  useDownloadApplicationLoans,
} from '../../hooks/loan';
import { Header } from '../Header';
import ReactPaginate from 'react-paginate';
import { Status } from '../Status';
import { useDefaultParams } from '../../hooks/params';
import { DatesFilter } from '../ApplicationFilter';
import { formatDate } from '../../utils/date';

export function Loans({ children, ...props }) {
  const {
    status,
    setStatus,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    page,
    setPage,
  } = useDefaultParams();

  const filters = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    status,
  };

  const { data, error, isFetching } = useApplicationLoans(page, filters);
  const { items, totalPages } = data || { items: [], totalPages: 0 };
  const [download, { isLoading: isDownloading }] =
    useDownloadApplicationLoans();

  return (
    <>
      <Header title="Aplikime për kredi">
        <DatesFilter
          startDate={startDate}
          endDate={endDate}
          status={status}
          isDownloading={isDownloading}
          onChange={({ startDate, endDate, status }) => {
            setStartDate(startDate);
            setEndDate(endDate);
            setStatus(status);
          }}
          onDownload={() => {
            download(filters);
          }}
        />
      </Header>

      {isFetching ? (
        <Loader />
      ) : (
        <>
          <Table variant="striped" colorScheme="gray" size="md">
            <Thead>
              <Tr>
                <Th>Klienti</Th>
                <Th>Telefoni</Th>
                <Th>E-mail</Th>
                <Th>Dega</Th>
                <Th isNumeric width="180px">
                  Data
                </Th>
                <Th isNumeric width="170px">
                  Statusi
                </Th>
                <Th isNumeric></Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map(item => (
                <Tr>
                  <Td>
                    <Link to={`/loan/${item.id}`}>
                      {item.creditor_name} {item.creditor_lastname}
                    </Link>
                  </Td>
                  <Td>{item.creditor_phone_number}</Td>
                  <Td>{item.creditor_email}</Td>
                  <Td>{item.branch.name}</Td>
                  <Td isNumeric>{formatDate(item.created_at)}</Td>
                  <Td isNumeric>
                    <Status tooltip={item.shortComment}>{item.status}</Status>
                  </Td>
                  <Td isNumeric>
                    <Button colorScheme="gray" variant="link" size="sm">
                      <Link to={`/loan/${item.id}`}>Shiko</Link>
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <ReactPaginate
            forcePage={page}
            previousLabel={'pas'}
            nextLabel={'përpara'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPages}
            marginPagesDisplayed={4}
            pageRangeDisplayed={4}
            onPageChange={({ selected }) => setPage(selected)}
            containerClassName={'container'}
            activeClassName={'current'}
            itemClass="page-item"
            linkClass="page-link"
          />
        </>
      )}
    </>
  );
}
