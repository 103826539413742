import React from 'react';
import { Select, Stack } from '@chakra-ui/react';
import { useApplicationLoanAssignTo } from '../hooks/loan';
import { useApplicationCardAssignTo } from '../hooks/card';
import { useApplicationContactAssignTo } from '../hooks/contact';
import { useAuth, useUsers } from '../hooks/auth';

export function AssignTo({ type, id, currentUserId, branchId }) {
  const [token, user] = useAuth();
  const { data, isFetching } = useUsers(0, {
    role: 'user',
    branchId: branchId || '',
  });
  const { items: users } = data || { items: [] };

  const [assignLoan] = useApplicationLoanAssignTo(id);
  const [assignCard] = useApplicationCardAssignTo(id);
  const [assignContact] = useApplicationContactAssignTo(id);

  function onStatusChange(event) {
    const assignId = event.target.value;

    if (type === 'loan') {
      assignLoan(assignId);
    }

    if (type === 'card') {
      assignCard(assignId);
    }

    if (type === 'contact') {
      assignContact(assignId);
    }
  }
  
  return (
    <Stack spacing={3} m={1} size="sm">
      <Select
        variant="filled"
        placeholder="Zgjidh përdoruesin..."
        onChange={onStatusChange}
        size="sm"
        isDisabled={isFetching}
        value={currentUserId}
      >
        {users.map(({ id, username }) => {
          return (
            <option value={id} key={id}>
              {username} {id === currentUserId ? '(zgjedhur)' : null}
            </option>
          );
        })}
      </Select>
    </Stack>
  );
}
