import React, { useState } from 'react';
import {
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  Text,
  Divider,
  Button,
} from '@chakra-ui/react';
import { FlexContainer } from '../Container';
import { useApplicationContacts } from '../../hooks/contact';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader';
import { Header } from '../Header';
import { useDepartments } from '../../hooks/department';

export function Departments({ children, ...props }) {
  const [page, setPage] = useState(0);
  const { status, data, error, isFetching } = useDepartments(page);

  if (isFetching) {
    return <Loader />;
  }

  const { items, totalPages } = data || { items: [], totalPages: 0 };

  return (
    <>
      <Header title="Lista e departamenteve"></Header>

      <Table variant="striped" colorScheme="gray" size="sm">
        <Thead>
          <Tr>
            <Th>Emri</Th>
            <Th>Drejtorë</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map(item => (
            <Tr>
              <Td style={{ width: '200px' }}>{item.name}</Td>
              <Td>{item.Users && item.Users.length ? item.Users.map((i) => i.username).join(" ") : "Asnjë" }</Td>
              <Td style={{ width: '100px' }}>
                <Link to={`department/` + item.id}>
                  <Button colorScheme="blue" variant="ghost" size="sm">
                    Redakto
                  </Button>
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
