import React from 'react';
import { Box, Divider, Flex, Spacer, Text } from '@chakra-ui/react';

export function Header({ title = '', children, subtitle }) {
  return (
    <Box margin="20px 0 25px 0">
      <Flex>
        <Box>
          <Text fontSize={subtitle ? 'md' : '2xl'}>{title}</Text>
        </Box>
        <Spacer />
        <Box>{children}</Box>
      </Flex>
      <Divider orientation="horizontal" />
    </Box>
  );
}
