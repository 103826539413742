import React from 'react';
import {
  Flex,
  Link as StyledLink,
  Stack,
  Button,
  Drawer,
  DrawerBody,
  IconButton,
  useDisclosure,
  DrawerCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { FlexContainer } from './Container';
import { MdArrowDownward, MdMenu } from 'react-icons/md';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { Visible } from './Visible';

function NavContainer({ children }) {
  return (
    <FlexContainer
      px="20px"
      height="70px"
      alignItems="center"
      justifyContent="space-between"
    >
      {children}
    </FlexContainer>
  );
}

function DesktopNav() {
  const history = useHistory();
  const [token, user, setAuth] = useAuth();

  return (
    <NavContainer>
      <Flex alignItems="center">
        <StyledLink mr={50} variant="unstyled">
          <img
            src="https://d1zuer7obi9700.cloudfront.net/images/fibank-logo.svg"
            alt="cowrywise-logo"
          />
        </StyledLink>

        {token && (
          <>
            {user && user.role === 'admin' ? (
              <>
                <NavLink to="/users">
                  <StyledLink
                    mr={50}
                    opacity={0.6}
                    color="#080808"
                    fontWeight={600}
                    variant="unstyled"
                    _hover={{ opacity: 1 }}
                  >
                    Përdorues
                  </StyledLink>
                </NavLink>

                <Menu>
                  <MenuButton
                    mr={50}
                    opacity={0.6}
                    color="#080808"
                    fontWeight={600}
                    variant="unstyled"
                    _hover={{ opacity: 1 }}
                    as={Button}
                  >
                    Konfigurime
                  </MenuButton>
                  <MenuList>
                    <MenuItem>
                      <Link to="/departments">Departamente</Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/branches">Degë</Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/short-comment">Komente të shpejta</Link>
                    </MenuItem>
                  </MenuList>
                </Menu>

                <Menu>
                  <MenuButton
                    mr={50}
                    opacity={0.6}
                    color="#080808"
                    fontWeight={600}
                    variant="unstyled"
                    _hover={{ opacity: 1 }}
                    as={Button}
                  >
                    Aplikime
                  </MenuButton>
                  <MenuList>
                    <MenuItem>
                      <Link to="/contacts">Kërkesa</Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/cards">Karta</Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/loans">Kredi</Link>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </>
            ) : null}

            {user && user.role === 'user' ? (
              <>
                <Visible
                  to={[
                    'BANKER',
                    'BRANCH_MANAGER',
                    'DEPUTY_BRANCH_MANAGER',
                    'DEPARTMENT_MANAGER',
                  ]}
                  departmentId={1}
                >
                  <NavLink to="/contacts">
                    <StyledLink
                      mr={50}
                      opacity={0.6}
                      color="#080808"
                      fontWeight={600}
                      variant="unstyled"
                      _hover={{ opacity: 1 }}
                    >
                      Kërkesa
                    </StyledLink>
                  </NavLink>
                </Visible>
                <Visible
                  to={[
                    'BANKER',
                    'BRANCH_MANAGER',
                    'DEPUTY_BRANCH_MANAGER',
                    'DEPARTMENT_MANAGER',
                  ]}
                  departmentId={2}
                >
                  <NavLink to="/cards">
                    <StyledLink
                      mr={50}
                      opacity={0.6}
                      color="#080808"
                      fontWeight={600}
                      variant="unstyled"
                      _hover={{ opacity: 1 }}
                    >
                      Aplikim për kartë
                    </StyledLink>
                  </NavLink>
                </Visible>
                <Visible
                  to={[
                    'BANKER',
                    'BRANCH_MANAGER',
                    'DEPUTY_BRANCH_MANAGER',
                    'DEPARTMENT_MANAGER',
                  ]}
                  departmentId={3}
                >
                  <NavLink to="/loans">
                    <StyledLink
                      mr={50}
                      opacity={0.6}
                      color="#080808"
                      fontWeight={600}
                      variant="unstyled"
                      _hover={{ opacity: 1 }}
                    >
                      Aplikim për kredi
                    </StyledLink>
                  </NavLink>
                </Visible>
              </>
            ) : null}
          </>
        )}
      </Flex>

      {token && (
        <Flex alignItems="center">
          <Menu>
            <MenuButton
              as={Button}
              textAlign={'left'}
              rightIcon={<MdArrowDownward />}
            >
              <Text size="sm">{user && user.username}</Text>
              <Text fontSize={11} fontWeight={'light'}>
                {getRole(user)}
              </Text>
            </MenuButton>
            <MenuList>
              <MenuItem>
                <Link to="/changepassword">Ndrysho fjalëkalimin</Link>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAuth('', null);
                  history.push('/login');
                }}
              >
                Dil
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      )}
    </NavContainer>
  );
}

function getRole(user) {
  if (!user) {
    return '';
  }
  if (user.role === 'admin') {
    return 'Administrator';
  }

  switch (user.accessLevel) {
    case 'BANKER':
      return 'Bankier';
    case 'BRANCH_MANAGER':
      return 'Drejtor dege';
    case 'DEPARTMENT_MANAGER':
      return 'Drejtor departamenti';
    case 'DEPUTY_BRANCH_MANAGER':
      return 'Zv. drejtor dege';
    default:
      return '';
  }
}

function MobileNav() {
  let { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <>
      <NavContainer>
        <StyledLink mr={50} variant="unstyled">
          <img
            src="https://d1zuer7obi9700.cloudfront.net/images/fibank-logo.svg"
            alt="cowrywise-logo"
          />
        </StyledLink>
        <IconButton icon={MdMenu} variant="ghost" onClick={onToggle} />
      </NavContainer>
      <Drawer
        size="2xl"
        isFullHeight
        isOpen={isOpen}
        placement="left"
        backgroundColor="blue.500"
      >
        <DrawerCloseButton color="white" onClick={onClose} />

        <DrawerBody>
          <Stack spacing="12px" mt="50px">
            <StyledLink
              color="white"
              fontSize="2rem"
              fontWeight={600}
              variant="unstyled"
            >
              Plan
            </StyledLink>
            <StyledLink
              color="white"
              fontSize="2rem"
              fontWeight={600}
              variant="unstyled"
            >
              Save
            </StyledLink>
            <StyledLink
              color="white"
              fontSize="2rem"
              fontWeight={600}
              variant="unstyled"
            >
              Invest
            </StyledLink>
          </Stack>
        </DrawerBody>
      </Drawer>
    </>
  );
}

export default function Navbar() {
  let isMobile = window.matchMedia('(max-width: 420px)').matches;

  return <DesktopNav />;
  // return isMobile ? <MobileNav /> : <DesktopNav />;
}
