import React from "react";
import { Box, Center, Flex, Spinner, Text } from "@chakra-ui/react";

export function Loader() {
  return (
    <Box>
        <Center>
            <Spinner></Spinner>
            <Text>Duke ngarkuar...</Text>
        </Center>
    </Box>
  );
}
