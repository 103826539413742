import React, { useState } from 'react';
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader';
import { Header } from '../Header';
import { useShortComments, useDeleteShortComment } from '../../hooks/short-comment';
import { EditForm } from './edit';

export function ShortComments({ children, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [page, setPage] = useState(0);
  const { status, data, isFetching, refetch, isRefetchError, isRefetching } =
    useShortComments(page);

  const [deleteShortComment] = useDeleteShortComment();

  console.log(
    'refetch,  isRefetchError, isRefetching',
    refetch,
    isRefetchError,
    isRefetching
  );

  if (isFetching) {
    return <Loader />;
  }

  const { items, totalPages } = data || { items: [], totalPages: 0 };

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Shto një koment të shpejtë</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditForm
              item={{ id: 0, data: ''}}
              createMode
              onSave={() => {
                onClose();
                refetch();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Header title="Lista e komenteve të shpejta">
        <Button colorScheme="blue" variant="link" onClick={onOpen}>
          Shto koment të shpejtë
        </Button>
      </Header>

      <Table variant="striped" colorScheme="gray" size="sm">
        <Thead>
          <Tr>
            <Th>Komenti</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map(item => (
            <Tr key={item.id}>
              <Td>{item.data}</Td>
              <Td style={{ width: '100px' }}>
                <Menu>
                  <MenuButton
                    as={Button}
                    colorScheme="blue"
                    variant="ghost"
                    size="sm"
                  >
                    Veprime
                  </MenuButton>
                  <MenuList>
                    <MenuItem>
                      <Link to={`short-comment/` + item.id}>Redakto</Link>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (window.confirm(`Jeni të sigurtë që doni të fshini "${item.data}"?`)) {
                          deleteShortComment(item.id);
                          toast({
                            title: `"${item.data}" u fshi.`,
                            status: 'success',
                            isClosable: true,
                          });
                        }
                      }}
                    >
                      Fshi
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
