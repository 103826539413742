import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr, HStack } from '@chakra-ui/react';
import { useApplicationContact } from '../../hooks/contact';
import { useParams } from 'react-router';
import { Loader } from '../Loader';
import { Header } from '../Header';
import { Status, UpdateStatus } from '../Status';
import { UpdateDetails } from '../UpdateDetails';
import { AssignTo } from '../Assign';
import { CommentBox } from '../Comment/Box';
import { Visible } from '../Visible';

export function Contact() {
  const { id } = useParams();
  const { status, data, error, isFetching } = useApplicationContact(id);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Header title={`Kërkesa #${data.id}`}>
        <HStack spacing="24px">
          <Visible
            to={[
              'BRANCH_MANAGER',
              'DEPUTY_BRANCH_MANAGER',
              'DEPARTMENT_MANAGER',
            ]}
          >
            <AssignTo
              id={data.id}
              type="contact"
              currentUserId={data.user ? data.user.id : null}
              branchId={data.branch ? data.branch.id : null}
            ></AssignTo>
          </Visible>

          <UpdateStatus
            currentStatus={data.status}
            id={data.id}
            type="contact"
          ></UpdateStatus>
        </HStack>
      </Header>

      <UpdateDetails data={data} />

      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th isNumeric width="40%"></Th>
            <Th width="50%"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td isNumeric>Statusi</Td>
            <Td>
              <Status>{data.status}</Status>
            </Td>
          </Tr>
          <Tr>
            <Td isNumeric>Emri</Td>
            <Td>{data.firstname}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Mbiemri</Td>
            <Td>{data.lastname}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Klient Fibank?</Td>
            <Td>{data.fibank_client ? 'Po' : 'Jo'}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Telefoni</Td>
            <Td>{data.phoneNumber}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>E-mail</Td>
            <Td>{data.email}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Dega</Td>
            <Td>{data.branch && data.branch.name}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Kerkesa</Td>
            <Td>{data.type}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Përshkrimi</Td>
            <Td>{data.description}</Td>
          </Tr>
        </Tbody>
      </Table>

      <CommentBox applicationId={id} type="contact" />
    </>
  );
}
