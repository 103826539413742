import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

export function UpdateDetails({ data }) {
  return (
    // <Box>
    //   <Text>Duke ngarkuar... {data.updated_at}</Text>
    //   <Text>Duke ngarkuar... {data.user.username}</Text>
    // </Box>
    <>
      {data && data.user && (
        <Table variant="simple" size="sm" style={{marginBottom: "20px"}}>
          <Thead>
            <Tr>
              <Th isNumeric width="40%"></Th>
              <Th width="50%"></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td isNumeric>Ndryshimi i fundit</Td>
              <Td>
                {data.updated_at}
              </Td>
            </Tr>
            <Tr>
              <Td isNumeric>Përdoruesi</Td>
              <Td>
              <Stack direction="row">

              <Avatar name={data.user.username} size={'sm'} />
              <Text style={{lineHeight: 2}}>{' '}{data.user.username} {data.user.deleted ? "(fshirë)" : null}</Text>

              </Stack>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      )}
    </>
  );
}
