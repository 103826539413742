import React, { useState } from 'react';
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader';
import { Header } from '../Header';
import { useBranches, useDeleteBranch } from '../../hooks/branch';
import { EditForm } from './edit';

export function Branches({ children, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [page, setPage] = useState(0);
  const { status, data, isFetching, refetch, isRefetchError, isRefetching } =
    useBranches(page);

  const [deleteBranch] = useDeleteBranch();

  console.log(
    'refetch,  isRefetchError, isRefetching',
    refetch,
    isRefetchError,
    isRefetching
  );

  if (isFetching) {
    return <Loader />;
  }

  const { items, totalPages } = data || { items: [], totalPages: 0 };

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Shto një degë</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditForm
              item={{ id: 0, name: '', address: '' }}
              createMode
              onSave={() => {
                onClose();
                refetch();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Header title="Lista e degëve">
        <Button colorScheme="blue" variant="link" onClick={onOpen}>
          Shto degë
        </Button>
      </Header>

      <Table variant="striped" colorScheme="gray" size="sm">
        <Thead>
          <Tr>
            <Th>Emri</Th>
            <Th>Adresa</Th>
            <Th>Drejtor</Th>
            <Th>Nen-drejtor</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map(item => (
            <Tr key={item.id}>
              <Td>{item.name}</Td>
              <Td>{item.address}</Td>
              <Td>{item.branchManager ? item.branchManager.username : ''}</Td>
              <Td>
                {item.deputyBranchManager
                  ? item.deputyBranchManager.username
                  : ''}
              </Td>
              <Td style={{ width: '100px' }}>
                <Menu>
                  <MenuButton
                    as={Button}
                    colorScheme="blue"
                    variant="ghost"
                    size="sm"
                  >
                    Veprime
                  </MenuButton>
                  <MenuList>
                    <MenuItem>
                      <Link to={`branch/` + item.id}>Redakto</Link>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (window.confirm(`Jeni të sigurtë që doni të fshini degën ${item.name}?`)) {
                          deleteBranch(item.id);
                          toast({
                            title: `Dega ${item.name} u fshi.`,
                            status: 'success',
                            isClosable: true,
                          });
                        }
                      }}
                    >
                      Fshi
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
