import { Button, HStack, Input, Select, Stack, Text } from '@chakra-ui/react';
import { addDays, addHours, format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdFileDownload } from 'react-icons/md';
import { useAuth } from '../hooks/auth';
import { Visible } from './Visible';

const statuses = {
  TODO: 'Në pritje',
  IN_PROCESS: 'Në progres',
  DONE: 'Përfunduar',
  CLOSED: 'Mbyllur',
};

function StatusFilter({ currentStatus, onChange }) {
  function onStatusChange(event) {
    const status = event.target.value;

    onChange && onChange(status);
  }

  return (
    <Stack spacing={3} m={1} size="sm">
      <Select
        variant="outline"
        colorScheme="gray"
        borderRadius={0}
        onChange={onStatusChange}
        size="sm"
        value={currentStatus}
        style={{ borderColor: 'var(--chakra-colors-gray-200)' }}
      >
        <option value="">
          Të gjitha {currentStatus === '' ? '(zgjedhur)' : null}
        </option>
        <option value="TODO">
          {statuses['TODO']} {currentStatus === 'TODO' ? '(zgjedhur)' : null}
        </option>
        <option value="IN_PROCESS">
          {statuses['IN_PROCESS']}{' '}
          {currentStatus === 'IN_PROCESS' ? '(zgjedhur)' : null}{' '}
        </option>
        <option value="DONE">
          {statuses['DONE']} {currentStatus === 'DONE' ? '(zgjedhur)' : null}
        </option>
        <option value="CLOSED">
          {statuses['CLOSED']}{' '}
          {currentStatus === 'CLOSED' ? '(zgjedhur)' : null}
        </option>
      </Select>
    </Stack>
  );
}

export const DatesFilter = ({
  startDate,
  endDate,
  status,
  onChange,
  onDownload,
  isDownloading,
}) => {
  const [token, user] = useAuth();

  const DateInput = ({ value, onClick }) => (
    <Button
      size="sm"
      onClick={onClick}
      variant="outline"
      colorScheme="gray"
      borderRadius={0}
    >
      {value}
    </Button>
  );

  return (
    <HStack spacing={4} height="50px">
      <Stack>
        <Text fontSize="xs" p={0} m={0} lineHeight={0.2}>
          Filtro sipas datës:
        </Text>
        <DatePicker
          selected={startDate}
          onChange={date => {
            if (date) {
              onChange({
                startDate: date,
                endDate,
                status,
              });
            }
          }}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          // @ts-ignore
          customInput={<DateInput />}
        />
      </Stack>

      <Stack>
        <Text fontSize="xs" p={0} m={0} lineHeight={0.2}></Text>
        <DatePicker
          selected={endDate}
          onChange={date => {
            if (date) {
              onChange({
                startDate,
                endDate: date,
                status,
              });
            }
          }}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          // @ts-ignore
          customInput={<DateInput />}
        />
      </Stack>

      <Stack>
        <Text fontSize="xs" p={0} m={0} lineHeight={0.2}>
          Filtro sipas statusit:
        </Text>
        <StatusFilter
          currentStatus={status}
          onChange={status => {
            onChange({
              startDate,
              endDate,
              status,
            });
          }}
        />
      </Stack>

      <Visible
        to={['ADMIN', 'DEPARTMENT_MANAGER']}
      >
        <Stack>
          <Text fontSize="xs" p={0} m={0} lineHeight={0.2}>
            {/* Dokument CSV */}
          </Text>
          <Button
            rightIcon={<MdFileDownload />}
            colorScheme="gray"
            size="sm"
            variant="ghost"
            width="100px"
            isLoading={isDownloading}
            onClick={() => {
              onDownload && onDownload();
            }}
          >
            Shkarko
          </Button>
        </Stack>
      </Visible>
    </HStack>
  );
};
