import React, { useContext, useState } from 'react';

export const AuthContext = React.createContext({
    token: "",
    user: null,
    set: () => null
});

export function AuthProvider({children}) {
  const itoken = localStorage.getItem('token');
  const iuser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  const [token, set] = useState(itoken);
  const [user, setUser] = useState(iuser);

  return <AuthContext.Provider value={{ token, set, user, setUser }}>{children}</AuthContext.Provider>;
}