import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr, HStack } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { Loader } from '../Loader';
import { useApplicationCard } from '../../hooks/card';
import { Header } from '../Header';
import { Status, UpdateStatus } from '../Status';
import { UpdateDetails } from '../UpdateDetails';
import { AssignTo } from '../Assign';
import { CommentBox } from '../Comment/Box';
import { Visible } from '../Visible';

export function Card() {
  const { id } = useParams();
  const { status, data, error, isFetching } = useApplicationCard(id);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Header title={`Aplikim për kartë #${data.id}`}>
        <HStack spacing="24px">
          <Visible
            to={[
              'BRANCH_MANAGER',
              'DEPUTY_BRANCH_MANAGER',
              'DEPARTMENT_MANAGER',
            ]}
          >
            <AssignTo
              id={data.id}
              type="card"
              currentUserId={data.user ? data.user.id : null}
              branchId={data.branch ? data.branch.id : null}
            ></AssignTo>
          </Visible>
          <UpdateStatus
            currentStatus={data.status}
            id={data.id}
            type="card"
          ></UpdateStatus>
        </HStack>
      </Header>
      <UpdateDetails data={data} />
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th isNumeric width="40%"></Th>
            <Th width="50%"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td isNumeric>Statusi</Td>
            <Td>
              <Status>{data.status}</Status>
            </Td>
          </Tr>
          <Tr>
            <Td isNumeric>Emri</Td>
            <Td>{data.name}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Mbiemri</Td>
            <Td>{data.lastname}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>E-mail</Td>
            <Td>{data.email}</Td>
          </Tr>

          <Tr>
            <Td isNumeric>Telefoni</Td>
            <Td>{data.phone_number}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Gjinia</Td>
            <Td>{data.gender}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Datëlindja</Td>
            <Td>{data.birthdate}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Dega</Td>
            <Td>{data.branch && data.branch.name}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Lloji i kartës</Td>
            <Td>{data.credit_card_type}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Limiti</Td>
            <Td>{data.credit_card_monthly_payment}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Monedha</Td>
            <Td>{data.credit_currency}</Td>
          </Tr>
        </Tbody>
      </Table>

      <CommentBox applicationId={id} type="card" />
    </>
  );
}
