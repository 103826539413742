import React from 'react';
import { useAuth } from '../hooks/auth';

export function Visible({ to = [], departmentId, children }) {
  const [token, user] = useAuth();

  if (to.indexOf('ADMIN') >= 0 && user && user.role === 'admin') {
    return children;
  }

  if (to.indexOf(user.accessLevel) >= 0 && user && user.role === 'user') {
    if (user.accessLevel === 'DEPARTMENT_MANAGER') {
      if (!departmentId) {
        return children
      }
      if (user.departmentId === departmentId) {
        return children;
      }
    } else {
      return children;
    }
  }

  return null;
}
