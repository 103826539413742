import { addDays } from 'date-fns';
import { useState, useMemo, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { formatJustDate } from '../utils/date';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useDefaultParams() {
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();

  const queryStartDate = query.get('startDate')
    ? new Date(query.get('startDate'))
    : null;
  const queryEndDate = query.get('endDate')
    ? new Date(query.get('endDate'))
    : null;
  const queryStatus = query.get('status');
  const queryPage = query.get('page') ? parseInt(query.get('page')) : 0;

  const [status, setStatus] = useState(queryStatus || '');
  const now = addDays(new Date(), 1);
  const last30days = addDays(now, -32);
  const [startDate, setStartDate] = useState(queryStartDate || last30days);
  const [endDate, setEndDate] = useState(queryEndDate || now);

  const [page, setPage] = useState(queryPage || 0);

  function updateQueryParams(params) {
    if (params.startDate) {
      params.startDate = formatJustDate(params.startDate);
    }

    if (params.endDate) {
      params.endDate = formatJustDate(params.endDate);
    }

    console.log({ queryStartDate, queryEndDate, queryStatus, queryPage });

    history.push({
      pathname: location.pathname,
      search: '?' + new URLSearchParams(params).toString(),
    });
  }

  useEffect(() => {
    updateQueryParams({ status, startDate, endDate, page });
  }, [startDate, endDate, page, status]);

  return {
    status,
    setStatus,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    page,
    setPage,
  };
}
