import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Md3DRotation, MdArrowForward, MdCheck, MdPages } from 'react-icons/md';
import { useApplicationLoanStatus } from '../hooks/loan';
import { useQueryCache } from 'react-query';
import { useApplicationCardStatus } from '../hooks/card';
import { useApplicationContactStatus } from '../hooks/contact';
import { useAuth } from '../hooks/auth';

const statuses = {
  TODO: 'Në pritje',
  IN_PROCESS: 'Në progres',
  DONE: 'Përfunduar',
  CLOSED: 'Mbyllur',
};

const statusColors = {
  TODO: 'gray',
  IN_PROCESS: 'blue',
  DONE: 'green',
  CLOSED: 'red',
};

export function ShortComment({ children }) {
  return (
    <>
      {children ? (
        children.data.length > 20 ? (
          <Tooltip
            label={children.data}
          >{`${children.data.slice(0, 17)}...`}</Tooltip>
        ) : (
          children.data
        )
      ) : (
        ''
      )}
    </>
  );
}

export function Status({ children, tooltip }) {
  return (
    <>
      <Tooltip label={tooltip ? tooltip.data : ''}>
        <Box display={'inline'}>
          {tooltip ? (
            <Icon as={MdArrowForward} color={'gray.400'}></Icon>
          ) : null}{' '}
          <Tag size={'md'} variant="solid" colorScheme={statusColors[children]}>
            {statuses[children]}
          </Tag>
        </Box>
      </Tooltip>
    </>
  );
}



export function UpdateStatus({ type, id, currentStatus }) {
  const [token, user] = useAuth();
  const [updateLoanStatus] = useApplicationLoanStatus(id);
  const [updateCardStatus] = useApplicationCardStatus(id);
  const [updateContactStatus] = useApplicationContactStatus(id);

  function onStatusChange(event) {
    const status = event.target.value;

    if (type === 'loan') {
      updateLoanStatus(status);
    }

    if (type === 'card') {
      updateCardStatus(status);
    }

    if (type === 'contact') {
      updateContactStatus(status);
    }
  }

  if (
    ['BANKER', 'BRANCH_MANAGER', 'DEPUTY_BRANCH_MANAGER', 'DEPARTMENT_MANAGER'].indexOf(
      user.role
    ) > 0
  ) {
    return null;
  }

  return (
    <Stack spacing={3} m={1} size="sm">
      <Select
        variant="filled"
        placeholder="Ndrysho statusin..."
        onChange={onStatusChange}
        size="sm"
        value={currentStatus}
      >
        <option value="TODO">
          {statuses['TODO']} {currentStatus === 'TODO' ? '(zgjedhur)' : null}
        </option>
        <option value="IN_PROCESS">
          {statuses['IN_PROCESS']}{' '}
          {currentStatus === 'IN_PROCESS' ? '(zgjedhur)' : null}{' '}
        </option>
        <option value="DONE">
          {statuses['DONE']} {currentStatus === 'DONE' ? '(zgjedhur)' : null}
        </option>
        <option value="CLOSED">
          {statuses['CLOSED']}{' '}
          {currentStatus === 'CLOSED' ? '(zgjedhur)' : null}
        </option>
      </Select>
    </Stack>
  );
}
