import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import { useContext } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { useHistory } from 'react-router';
import { AuthContext } from '../providers/auth';
import {
  makeCreateOne,
  makeDeleteOne,
  makeGetAll,
  makeGetOne,
  makeUpdateOne,
} from './base';

export function useLogin() {
  const toast = useToast();
  // const queryCache = useQueryCache()
  const [t, u, set] = useAuth();

  function logout(err) {
    const message = err.response.data.error || 'Problem i panjohur';
    const statusCode = err.response.status;

    toast({
      title: `Gabim: ${message}`,
      status: 'error',
      isClosable: true,
    });

    if (statusCode === 401) {
      set(null, null);
    }
  }

  return [
    useMutation(async ({ email, password }) => {
      const { data } = await axios.post(
        `https://manage.fibank.al/applications/login`,
        { email, password }
      );
      return data;
    }),
    logout,
  ];
}

export function useAuth() {
  const history = useHistory();
  const { token, set, user, setUser } = useContext(AuthContext);

  const setAuth = (t, user) => {
    set(t);
    setUser(user);
    if (t) {
      localStorage.setItem('token', t);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      history.push('/login');
    }
  };

  return [token, user, setAuth];
}

export function usePasswordReset(email) {
  return useUpdateUser("reset-password")
};

export function useChangePassword() {
  return useUpdateUser("change-password")
};

export const useUsers = makeGetAll('user');
export const useUser = makeGetOne('user');
export const useCreateUser = makeCreateOne('user');
export const useUpdateUser = makeUpdateOne('user');
export const useDeleteUser = makeDeleteOne('user');
