import React, { useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { Loader } from '../Loader';
import { Header } from '../Header';
import {
  useAuth,
  useChangePassword,
  usechangePassword,
  useUser,
} from '../../hooks/auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useBranches } from '../../hooks/branch';
import { useDepartments } from '../../hooks/department';
import { useParams } from 'react-router';
import { PasswordInput, SelectInput, TextInput } from '../Form';

const ChangePasswordForm = ({ user }) => {
  const toast = useToast();
  const [changePassword] = useChangePassword();

  const ValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(5, 'Fjalëkalimi duhet të jetë më i gjatë')
      .max(50, 'Too Long!')
      .required('I detyrueshëm'),
    confirmNewPassword: Yup.string().test({
      name: 'same',
      exclusive: false,
      params: {},
      message: 'Fjalëkalimi i ri nuk përputhet',
      test: function (value) {
        // You can access the price field with `this.parent`.
        return value === this.parent.newPassword;
      },
    }),
  });

  const formik = useFormik({
    initialValues: {
      email: user.email,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    onSubmit: ({ oldPassword, newPassword, confirmNewPassword }) => {
      formik.setSubmitting(true);
      changePassword({ oldPassword, newPassword });
      formik.setSubmitting(false);
      toast({
        title: `Ndryshimet u ruajten`,
        status: 'success',
        isClosable: true,
      });
    },
    validationSchema: ValidationSchema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput formik={formik} id="email" name="E-mail" disabled />

      <PasswordInput formik={formik} id="oldPassword" name="Fjalëkalimi i vjetër" />
      <PasswordInput formik={formik} id="newPassword" name="Fjalëkalimi i ri" />
      <PasswordInput
        formik={formik}
        id="confirmNewPassword"
        name="Konfirmo fjalëkalimin e ri"
      />

      <Button
        mt={4}
        colorScheme="blue"
        isLoading={formik.isSubmitting}
        type="submit"
      >
        Ruaj
      </Button>
    </form>
  );
};

export function ChangePassword({ children, ...props }) {
  const [token, user] = useAuth();
  const id = user.id;
  const { status, data, error, isFetching } = useUser(id);

  if (isFetching) {
    return <Loader />;
  }

  const { items, totalPages } = data || { items: [], totalPages: 0 };

  return (
    <>
      <Header title={`Ndrysho fjalëkalimin`}></Header>
      <ChangePasswordForm user={data} />
    </>
  );
}
