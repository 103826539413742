import React from 'react';
import {
  FormControl,
  Textarea,
  FormLabel,
  Button,
  Box,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Spacer,
  Text,
  Alert,
  AlertIcon,
  Icon,
  Center,
  Tooltip,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextareaInput } from '../Form';
import { MdArrowUpward, MdInfo } from 'react-icons/md';
import { useShortComments } from '../../hooks/short-comment';

export const CommentForm = ({ item = { text: '' }, onSave, onShortComment }) => {
  const { data, isLoading } = useShortComments()
  const SignupSchema = Yup.object().shape({
    text: Yup.string().required('I detyrueshëm'),
  });

  const formik = useFormik({
    initialValues: {
      text: item.text || '',
    },
    onSubmit: val => {
      formik.setSubmitting(true);
      onSave(val);
      formik.setSubmitting(false);

      formik.values.text = '';
    },
    validationSchema: SignupSchema,
  });
  return (
    <Box mb={10}>
      <Divider mb={5} style={{ border: '1px dotted lightgray' }} />
      <form onSubmit={formik.handleSubmit}>
        <TextareaInput
          id="text"
          formik={formik}
          name="Shto një koment"
          placeholder={'Komenti juaj...'}
          style={{ border: '1px solid lightgray' }}
        />

        <Flex mt={4}>
          <Box>
            <Button
              colorScheme="blue"
              isLoading={formik.isSubmitting}
              type="submit"
            >
              Ruaj komentin
            </Button>
          </Box>
          <Spacer />
          <Flex>
            <Center>
              <Tooltip
                shouldWrapChildren
                placement="top"
                label="Shto një koment nga lista e komenteve të shpejta. Zakonisht përdoret për të përcaktuar arsyen e mbylljes së një aplikimi."
                aria-label="A tooltip"
              >
                <Icon as={MdInfo} color="gray" />
              </Tooltip>
              <Menu direction="ltr" isLoading={isLoading}>
                <MenuButton as={Text} color="gray" fontWeight={'bold'} p={3}>
                  Koment i shpejtë
                </MenuButton>
                <MenuList>
                  {data?.items.map(({ id, data }) => (
                    <MenuItem key={id} onClick={e => onShortComment(id)}>
                      {data}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Center>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};
