import React from 'react';
import {
  HStack,
  Flex,
  Heading,
  Text,
  Stack,
  Avatar,
  Spacer,
  Box,
} from '@chakra-ui/react';
import { formatDate } from '../../utils/date';


const CommentWrapper = ({ children }) => {
  return (
    <Box marginBottom={5}>
      <HStack width="100%" spacing="24px">
        {children}
      </HStack>
    </Box>
  );
};

const CommentContent = ({ children }) => {
  return (
    <Stack
      bg="#f3f3f3"
      // boxShadow={'lg'}
      p={4}
      width="100%"
      rounded={'md'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: '#f3f3f3',
        pos: 'absolute',
        top: '20px',
        left: '-8px',
        transform: 'translateX(-50%) rotate(90deg)',
      }}
    >
      {children}
    </Stack>
  );
};

const CommentHeading = ({ date, children }) => {
  return (
    <HStack spacing="24px">
      <Heading as={'h3'} fontSize={'sm'}>
        {children}
      </Heading>
      <Spacer />
      <Text fontSize="sm" color="gray">
        {date}
      </Text>
    </HStack>
  );
};

const CommentText = ({ children }) => {
  return <Text fontSize={'sm'}>{children}</Text>;
};

const CommentAvatar = ({ name }) => {
  return (
    <Flex align={'center'} direction={'column'}>
      <Avatar name={name} mb={2} />
    </Flex>
  );
};

export const Comment = ({ name, message, date, deleted }) => {
  return (
    <CommentWrapper>
      <CommentAvatar name={name} />
      <CommentContent>
        <CommentHeading date={formatDate(date)}>{name} {deleted ? "(fshirë)" : null}</CommentHeading>
        <CommentText>{message}</CommentText>
      </CommentContent>
    </CommentWrapper>
  );
};