import React from "react";
import { Flex } from "@chakra-ui/react";

export function FlexContainer({ children, ...props }) {
  return (
    <Flex margin="0 auto" maxWidth="1310px" {...props}>
      {children}
    </Flex>
  );
}
