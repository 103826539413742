import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useLogin } from './auth';

let instance = axios.create({
  headers: {
    Authorization: '',
  },
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

export function makeGetAll(name) {
  return function useApplicationCards(page, filter = {}) {
    const queryCache = useQueryCache();
    const [token, logout] = useLogin();

    var qryString = '';

    Object.keys(filter).forEach(key => {
      const val = filter[key];
      qryString += `&${key}=${val}`;
    });

    return useQuery(
      [`${name}s`, { page, ...filter }],
      async () => {
        try {
          const { data } = await instance.get(
            `https://manage.fibank.al/applications/${name}?page=${
              page + 1
            }${qryString}`
          );
          return data;
        } catch (err) {
          logout(err);
        }
      },
      {}
    );
  };
}

export function makeGetOne(name) {
  return function useApplicationCard(id) {
    const [token, logout] = useLogin();

    return useQuery([name, { id: +id }], async () => {
      try {
        const { data } = await instance.get(
          `https://manage.fibank.al/applications/${name}/${id}`
        );
        return data;
      } catch (err) {
        logout(err);
      }
    });
  };
}

export function makeUpdateOneStatus(name) {
  return function (id) {
    const queryCache = useQueryCache();
    const [token, logout] = useLogin();

    return useMutation(
      async status => {
        try {
          const { data } = await instance.put(
            `https://manage.fibank.al/applications/${name}/${id}`,
            { status }
          );
          return data;
        } catch (err) {
          logout(err);
        }
      },
      {
        onSuccess: data => {
          queryCache.refetchQueries([name, { id: +data.id }]);
        },
      }
    );
  };
}

export function makeAssignTo(name) {
  return function (id) {
    const queryCache = useQueryCache();
    const [token, logout] = useLogin();

    return useMutation(
      async assignId => {
        try {
          const { data } = await instance.put(
            `https://manage.fibank.al/applications/${name}/${id}/assign`,
            { assignId }
          );
          return data;
        } catch (err) {
          logout(err);
        }
      },
      {
        onSuccess: data => {
          // queryCache.setQueryData([name, { id: +data.id }], data);
          queryCache.refetchQueries([name, { id: +data.id }]);
          queryCache.invalidateQueries([
            `${name}`,
            'comments',
            { id: data.id },
          ]);
        },
      }
    );
  };
}

export function makeUpdateOne(name) {
  return function (id) {
    const queryCache = useQueryCache();
    const [token, logout] = useLogin();

    return useMutation(
      async updateData => {
        try {
          const { data } = await instance.put(
            `https://manage.fibank.al/applications/${name}/${id}`,
            updateData
          );
          return data;
        } catch (err) {
          logout(err);
        }
      },
      {
        onSuccess: data => {
          // Query Invalidations
          // queryCache.invalidateQueries(['card', { id: +id}]);
          queryCache.setQueryData([name, { id: +data.id }], data);
        },
      }
    );
  };
}

export function makeCreateOne(name) {
  return function () {
    const queryCache = useQueryCache();
    const [token, logout] = useLogin();

    return useMutation(
      async createData => {
        try {
          const { data } = await instance.post(
            `https://manage.fibank.al/applications/${name}`,
            createData
          );
          return data;
        } catch (err) {
          logout(err);
        }
      },
      {
        onSuccess: data => {
          //
          // Query Invalidations
          // queryCache.invalidateQueries([`${name}s`, { page: 0 }],);
          queryCache.setQueryData([name, { id: +data.id }], data);
          // queryCache.fetchQuery([`${name}s`, { page: 0 }])
        },
      }
    );
  };
}

export function makeDeleteOne(name) {
  return function () {
    const queryCache = useQueryCache();
    const [token, logout] = useLogin();

    return useMutation(
      async id => {
        try {
          const { data } = await instance.delete(
            `https://manage.fibank.al/applications/${name}/${id}`
          );
          return data;
        } catch (err) {
          logout(err);
        }
      },
      {
        onSuccess: data => {
          //
          // Query Invalidations
          // queryCache.invalidateQueries([`${name}s`, { page: 0 }],);
          queryCache.setQueryData([name, { id: +data.id }], null);
          // queryCache.fetchQuery([`${name}s`, { page: 0 }])
        },
      }
    );
  };
}

export function makeCreateOneComment(name) {
  return function () {
    const queryCache = useQueryCache();
    const [token, logout] = useLogin();

    return useMutation(
      async createData => {
        try {
          const { data } = await instance.post(
            `https://manage.fibank.al/applications/${name}/comment`,
            createData
          );
          return data;
        } catch (err) {
          logout(err);
        }
      },
      {
        onSuccess: data => {
          //
          // Query Invalidations
          queryCache.invalidateQueries([
            `${name}`,
            'comments',
            { id: data.application_id },
          ]);
        },
      }
    );
  };
}

export function makeGetAllComments(name) {
  return function useApplicationCard(id) {
    const [token, logout] = useLogin();

    return useQuery([name, 'comments', { id: +id }], async () => {
      try {
        const { data } = await instance.get(
          `https://manage.fibank.al/applications/${name}/${id}/comments`
        );
        return data;
      } catch (err) {
        logout(err);
      }
    });
  };
}

export function makeDownloadExcel(name) {
  return function useApplicationCards() {
    const [token, logout] = useLogin();

    return useMutation(async (filter = {}) => {
      var qryString = '';

      Object.keys(filter).forEach(key => {
        if (qryString === '') {
          qryString = '?';
        }
        const val = filter[key];
        qryString += `${key}=${val}&`;
      });

      try {
        const { data, status } = await instance.get(
          `https://manage.fibank.al/applications/${name}/download${qryString}`
        );

        if (status === 200) {
          const { url } = data;
          let a = document.createElement('a');
          a.href = url;
          a.download = url.split('/').pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        return data;
      } catch (err) {
        logout(err);
      }
    }, {});
  };
}
