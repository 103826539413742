import React from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { CommentForm } from './Form';
import { Comment } from './Comment';
import { EventUpdate } from './EventUpdate';
import { Header } from '../Header';
import {
  useApplicationLoanComments,
  useCreateApplicationLoanComment,
} from '../../hooks/loan';
import { Status } from '../Status';
import {
  useApplicationContactComments,
  useCreateApplicationContactComment,
} from '../../hooks/contact';
import {
  useApplicationCardComments,
  useCreateApplicationCardComment,
} from '../../hooks/card';
import { Visible } from '../Visible';

export const CommentBoxBase = ({ items, onSave, onShortComment }) => {
  return (
    <Box>
      <Header title="Komente dhe historiku i veprimeve"></Header>

      {items.map(item => {
        if (item.type === 'COMMENT') {
          return (
            <Comment
              key={item.id}
              date={item.created_at}
              name={item.author.username}
              deleted={item.author.deleted}
              message={item.data}
            />
          );
        }

        if (item.type === 'SHORT_COMMENT') {
          return (
            <Comment
              key={item.id}
              date={item.created_at}
              name={item.author.username}
              deleted={item.author.deleted}
              message={item.data}
            />
          );
        }

        if (item.type === 'ASSIGNMENT') {
          return (
            <EventUpdate
              key={item.id}
              date={item.created_at}
              name={item.author.username}
              deleted={item.author.deleted}
              message={`I adresoi aplikimin bankierit ${item.affectedUser.username}`}
            />
          );
        }

        if (item.type === 'STATUS_UPDATE') {
          return (
            <EventUpdate
              status={item.data}
              key={item.id}
              date={item.created_at}
              name={item.author.username}
              deleted={item.author.deleted}
              message={
                <>
                  Ndryshoi statusin e aplikimit në{' '}
                  {
                      <Status>{item.data}</Status>
                  }
                </>
              }
            />
          );
        }

        return null;
      })}

      <Visible
        to={[
          'BANKER',
          'BRANCH_MANAGER',
          'DEPUTY_BRANCH_MANAGER',
          'DEPARTMENT_MANAGER',
          'ADMIN',
        ]}
      >
        <CommentForm
          onSave={val => {
            onSave(val);
          }}
          onShortComment={onShortComment}
        />
      </Visible>
    </Box>
  );
};

export const CommentBoxLoan = ({ applicationId, onSave }) => {
  const toast = useToast();
  const { data = { items: [] }, isFetching } =
    useApplicationLoanComments(applicationId);
  const [createComment] = useCreateApplicationLoanComment();

  return (
    <CommentBoxBase
      items={data.items}
      onSave={async val => {
        await createComment({
          application_id: applicationId,
          text: val.text,
          type: 'COMMENT',
        });
        toast({
          title: `Ndryshimet u ruajten`,
          status: 'success',
          isClosable: true,
        });
      }}
      onShortComment={async shortCommentId => {
        await createComment({
          application_id: applicationId,
          type: 'SHORT_COMMENT',
          shortCommentId: shortCommentId,
        });
        toast({
          title: `Ndryshimet u ruajten`,
          status: 'success',
          isClosable: true,
        });
      }}
    ></CommentBoxBase>
  );
};

export const CommentBoxContact = ({ applicationId, onSave }) => {
  const toast = useToast();
  const { data = { items: [] }, isFetching } =
    useApplicationContactComments(applicationId);
  const [createComment] = useCreateApplicationContactComment();

  return (
    <CommentBoxBase
      items={data.items}
      onSave={async val => {
        await createComment({
          application_id: applicationId,
          text: val.text,
          type: 'COMMENT',
        });
        toast({
          title: `Ndryshimet u ruajten`,
          status: 'success',
          isClosable: true,
        });
      }}
      onShortComment={async shortCommentId => {
        await createComment({
          application_id: applicationId,
          type: 'SHORT_COMMENT',
          shortCommentId: shortCommentId,
        });
        toast({
          title: `Ndryshimet u ruajten`,
          status: 'success',
          isClosable: true,
        });
      }}
    ></CommentBoxBase>
  );
};

export const CommentBoxCard = ({ applicationId, onSave }) => {
  const toast = useToast();
  const { data = { items: [] }, isFetching } =
    useApplicationCardComments(applicationId);
  const [createComment] = useCreateApplicationCardComment();

  return (
    <CommentBoxBase
      items={data.items}
      onSave={async val => {
        await createComment({
          application_id: applicationId,
          text: val.text,
          type: 'COMMENT',
        });
        toast({
          title: `Ndryshimet u ruajten`,
          status: 'success',
          isClosable: true,
        });
      }}
      onShortComment={async shortCommentId => {
        await createComment({
          application_id: applicationId,
          type: 'SHORT_COMMENT',
          shortCommentId: shortCommentId,
        });
        toast({
          title: `Ndryshimet u ruajten`,
          status: 'success',
          isClosable: true,
        });
      }}
    ></CommentBoxBase>
  );
};

export const CommentBox = ({ applicationId, type }) => {
  if (type === 'loan') {
    return <CommentBoxLoan applicationId={applicationId} />;
  }
  if (type === 'card') {
    return <CommentBoxCard applicationId={applicationId} />;
  }
  if (type === 'contact') {
    return <CommentBoxContact applicationId={applicationId} />;
  }
  return null;
};
