import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr, HStack } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { Loader } from '../Loader';
import { useApplicationLoan } from '../../hooks/loan';
import { Header } from '../Header';
import { Status, UpdateStatus } from '../Status';
import { UpdateDetails } from '../UpdateDetails';
import { AssignTo } from '../Assign';
import { CommentBox } from '../Comment/Box';
import { Visible } from '../Visible';

export function Loan() {
  const { id } = useParams();
  const { status, data, error, isFetching } = useApplicationLoan(id);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Header title={`Aplikim për kredi #${data.id}`}>
        <HStack spacing="24px">
          <Visible
            to={[
              'BRANCH_MANAGER',
              'DEPUTY_BRANCH_MANAGER',
              'DEPARTMENT_MANAGER',
            ]}
          >
            <AssignTo
              id={data.id}
              type="loan"
              currentUserId={data.user ? data.user.id : null}
              branchId={data.branch ? data.branch.id : null}
            ></AssignTo>
          </Visible>

          <UpdateStatus
            currentStatus={data.status}
            id={data.id}
            type="loan"
          ></UpdateStatus>
        </HStack>
      </Header>
      <UpdateDetails data={data} />

      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th isNumeric width="40%"></Th>
            <Th width="50%"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td isNumeric>Statusi</Td>
            <Td>
              <Status>{data.status}</Status>
            </Td>
          </Tr>
          <Tr>
            <Td colSpan="2" fontWeight="bold">
              Detajet e klientit
            </Td>
          </Tr>
          <Tr>
            <Td isNumeric>Emri</Td>
            <Td>{data.creditor_name}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Mbiemri</Td>
            <Td>{data.creditor_lastname}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Telefoni</Td>
            <Td>{data.creditor_phone_number}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>E-mail</Td>
            <Td>{data.creditor_email}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Gjinia</Td>
            <Td>{data.creditor_gender}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Datëlindja</Td>
            <Td>{data.creditor_birthdate}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Statusi</Td>
            <Td>{data.creditor_status}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Persona në varësi</Td>
            <Td>{data.number_of_dependents}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Arsimimi</Td>
            <Td>{data.creditor_education}</Td>
          </Tr>          
          <Tr>
            <Td isNumeric>Dega</Td>
            <Td>{data.branch && data.branch.name}</Td>
          </Tr>
          <Tr>
            <Td colSpan="2" fontWeight="bold">
              Të dhëna shtesë mbi kredinë
            </Td>
          </Tr>
          <Tr>
            <Td isNumeric>Kredimarrësi</Td>
            <Td>{data.creditor_type}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Lloji i kredisë</Td>
            <Td>{data.mortgage_type}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Monedha</Td>
            <Td>{data.credit_currency}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Muajt</Td>
            <Td>{data.credit_months}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Arsyeja</Td>
            <Td>{data.credit_reason}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Vlera e kredisë</Td>
            <Td>{data.credit_total}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Numri i garantorëve</Td>
            <Td>{data.credit_warranters}</Td>
          </Tr>

          <Tr>
            <Td colSpan="2" fontWeight="bold">
              Të dhënat e punësimit
            </Td>
          </Tr>
          <Tr>
            <Td isNumeric>Statusi i punësimit</Td>
            <Td>{data.work_status}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Pagesa mujore të kredive</Td>
            <Td>{data.creditor_monthly_credit_payments}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Monedha</Td>
            <Td>{data.creditor_monthly_credit_payments_currency}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Pagesa me vonesë</Td>
            <Td>{data.delayed_payments}</Td>
          </Tr>

          <Tr>
            <Td isNumeric>Të ardhura neto</Td>
            <Td>{data.creditor_revenue}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Monedha</Td>
            <Td>{data.creditor_revenue_currency}</Td>
          </Tr>

          <Tr>
            <Td colSpan="2" fontWeight="bold">
              Të tjera
            </Td>
          </Tr>
          <Tr>
            <Td isNumeric>Ku e merrni pagën</Td>
            <Td>{data.creditor_salary_info}</Td>
          </Tr>
          <Tr>
            <Td isNumeric>Ku keni dëgjuar për Fibank?</Td>
            <Td>{data.other_info}</Td>
          </Tr>
        </Tbody>
      </Table>

      <CommentBox applicationId={id} type="loan" />
    </>
  );
}
